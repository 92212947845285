/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Link, Typography } from "@material-ui/core";
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  rootRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "40% ",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: "0.8rem",
    },
  },
  heading: {
    margin: "0rem 0rem 1rem 0rem",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.8rem",
    color: "#4A225B",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  iconLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.5rem",
    color: "#666666",
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
  },
}));

const FooterLinks = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.rootRow}>
        <Typography className={classes.heading}>Contact us</Typography>
        {/* Phone */}
        <Link
          href="tel:+919513689999"
          rel="noopener"
          title="Phone"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/phone.svg"
            alt={AppConfig.imageAltText}
            width={18}
            height={18}
          />
          +91 9513689999
        </Link>

        {/* WhatsApp */}
        <Link
          href="https://wa.me/+919513689999"
          rel="noopener"
          title="Whatsapp"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/whatsapp.svg"
            alt={AppConfig.imageAltText}
            width={18}
            height={18}
          />
          +91 9513689999
        </Link>

        {/* Email */}
        <Link
          href="mailto:mail@heavie.co"
          rel="noopener"
          title="Email"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/mail.svg"
            alt={AppConfig.imageAltText}
            width={18}
            height={18}
          />
          mail@heavie.co
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterLinks;
