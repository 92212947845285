/* Common Imports */
import React from "react";
import { withRouter, useRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Drawer, Link, List, Divider } from "@material-ui/core/";
import SideNavButton from "./SideNavButton";
import SideNavDropdownUser from "./SideNavDropdownUser";

/* Function Imports */
// import useUser from "../../../lib/useUser";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  drawer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoAndListDiv: {
    width: 240,
  },
  drawerLogo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  // callBtnDiv: {
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  // },
  // callBtn: {
  //   height: "40px",
  //   width: "200px",
  //   background: "linear-gradient(90deg, #F74134 0%, #EB1C61 100%)",
  //   boxShadow:
  //     "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  //   borderRadius: "5",
  //   color: "#ffffff",
  //   padding: "0.625rem 0.938rem",
  //   textTransform: "capitalize",
  // },
}));

const SideNav = (props) => {
  const classes = useStyles();
  const router = useRouter();
  // const { user, mutateUser } = useUser();

  return (
    <React.Fragment>
      {/* SideNav Phone */}
      <Drawer anchor="left" open={props.open} onClose={props.onClose}>
        <div className={classes.drawer}>
          <div className={classes.logoAndListDiv}>
            {/* Logo */}
            <div className={classes.drawerLogo}>
              <Link
                href="/"
                rel="noopener"
                aria-label="Heavie"
                title="Home"
                className={classes.link}
              >
                <img
                  src="/images/logo/logo.svg"
                  alt={AppConfig.imageAltText}
                  width={140}
                  height={40}
                  loading="Eager"
                  referrerPolicy="no-referrer"
                />
              </Link>
            </div>
            <Divider />
            {/* Nav Items List */}
            <div>
              <List>
                <SideNavButton href="/" text="Home" />
                <SideNavButton href="/eden" text="Heavie Eden" />
                <SideNavButton href="/construction" text="Construction" />
                <SideNavButton href="/about-us" text="About Us" />
                <SideNavButton
                  href="#"
                  text="Contact Us"
                  onClick={(event) => {
                    event.preventDefault();
                    router.push("#contactus");

                    // scrollIntoView(
                    //   document.getElementById("rootDiv"),
                    //   document.getElementById("contactus")
                    // );
                    // document.getElementById("contactus").scrollTop();
                    props.onClose();
                  }}
                />
                {!props.user?.isLoggedIn && (
                  <SideNavButton href="/sign-in" text="Sign In" />
                )}
                {props.user?.isLoggedIn && (
                  <SideNavDropdownUser
                    href={
                      props.user?.role == "client"
                        ? "/user-dashboard"
                        : "/staff-dashboard"
                    }
                    text={props.user?.name ? props.user?.name : "My Dashboard"}
                    // open={props.router.pathname.includes("/company")}
                    onClickSignOut={async (e) => {
                      e.preventDefault();
                      // mutateUser(
                      await fetch(
                        encodeURI(
                          AppConfig.apiUrlPrefix +
                            "authentication/user-sign-out",
                        ),
                        {
                          method: "POST",
                          cache: "no-store",
                        },
                      ),
                        false;
                      // );
                      window.location = "/sign-in";
                    }}
                  />
                )}
              </List>
            </div>
          </div>

          {/* Call Us Button */}
          {/* <div className={classes.callBtnDiv}>
            <Button
              className={classes.callBtn}
              href="tel:18005720999"
              title="Call Us"
            >
              Call Us
            </Button>
          </div> */}
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(SideNav);
