/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import BeegruContainer from "./BeegruContainer";
import FooterBody from "./footer-components/FooterBody";
import FooterContactLinks from "./footer-components/FooterContactLinks";
import FooterSocialIcons from "./footer-components/FooterSocialIcons";
import FooterCopyrightInfo from "./footer-components/FooterCopyrightInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0rem 0rem 0rem 0rem",
    backgroundColor: "#FAFAFA",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  footerRef: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  iconsAndCopyright: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <BeegruContainer>
          <FooterBody />
          <div className={classes.footerRef}>
            <FooterContactLinks />
            <div className={classes.iconsAndCopyright}>
              <FooterCopyrightInfo />
              <FooterSocialIcons />
            </div>
          </div>
        </BeegruContainer>
      </div>
    </React.Fragment>
  );
};

export default Footer;
