/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
  },
  heading: {
    margin: "0rem 0rem 2rem 0rem",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.8rem",
    color: "#4A225B",
    [theme.breakpoints.down("sm")]: {
      margin: "1.5rem 0rem 1rem 0rem",
    },
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#666666",
  },
}));

const FooterCompanyAddress = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography className={classes.heading}>Corporate Office</Typography>
        <Typography
          variant="body2"
          component="span"
          className={classes.bodyText}
        >
          1090i, 3<sup>rd</sup> Floor, 18<sup>th</sup> Cross Road, HSR Layout, 3
          <sup>rd</sup> Sector, Bengaluru, Karnataka, India 560 102
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default FooterCompanyAddress;
