/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    alignItems: "center",
    width: "100%",
  },
  logoClass: {
    margin: "1.8rem 0rem -1.8rem 0rem",
    width: "12.5rem",
    height: "3.5rem",
    [theme.breakpoints.up("lg")]: {
      margin: "1.8rem -2rem -1.8rem 2rem",
    },
  },
  sketch: {
    position: "absolute",
    // bottom: 0,
    // left: 0,
    top: 0,
    right: 0,
    zIndex: -1,
    height: "17.5rem",
    width: "17.5rem",
    [theme.breakpoints.up("sm")]: {
      height: "20rem",
      width: "20rem",
    },
    [theme.breakpoints.up("md")]: {
      height: "30rem",
      width: "30rem",
    },
    [theme.breakpoints.up("lg")]: {
      height: "20rem",
      width: "20rem",
    },
    [theme.breakpoints.up("xl")]: {
      height: "30rem",
      width: "30rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "40rem",
      width: "40rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      height: "75rem",
      width: "75rem",
    },
  },
}));

const ComingSoonPlaceholder = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* <div className={classes.logo}>
        <img
          className={classes.logoClass}

          alt={AppConfig.imageAltText}

          loading="lazy"
          referrerPolicy="no-referrer"
          height={56}
          width={200}
          src="/images/logo/logo.svg"
        />
      </div> */}
      <img
        className={classes.sketch}
        alt={AppConfig.imageAltText}
        loading="lazy"
        referrerPolicy="no-referrer"
        height={280}
        width={280}
        src="/images/illustrations/under-construction/under-construction-new.svg"
      />
    </React.Fragment>
  );
};

export default ComingSoonPlaceholder;
