/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
  },
  logo: {
    height: "3.5rem",
    width: "10.125rem",
  },
}));

const FooterCompanyLogo = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <img
          className={classes.logo}
          src="/images/logo/logo.svg"
          alt={AppConfig.imageAltText}
          width={162}
          height={56}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
      </div>
    </React.Fragment>
  );
};

export default FooterCompanyLogo;
