/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Typography } from "@material-ui/core";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  rootRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "60% ",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0rem 0rem 0rem 0rem",
    },
  },
  iconLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.5rem",
    color: "#666666",
  },
}));

const FooterCopyrightInfo = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.rootRow}>
        <Typography
          variant="body2"
          component="span"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/copyright.svg"
            alt={AppConfig.imageAltText}
            width={18}
            height={18}
          />
          {new Date().getFullYear()} Heavie Developers Private Limited. All
          Rights Reserved.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default FooterCopyrightInfo;
