/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import FooterCompanyAddress from "./FooterCompanyAddress";
import FooterCompanyLinks from "./FooterCompanyLinks";
import FooterCompanyLogo from "./FooterCompanyLogo";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "2rem 0rem 4rem 0rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "2rem 0rem 1.5rem 0rem",
    },
  },
}));

const FooterBody = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.footerContainer}>
        <FooterCompanyLogo />
        <FooterCompanyAddress />
        <FooterCompanyLinks />
      </div>
    </React.Fragment>
  );
};

export default FooterBody;
